<template>
  <div
    class="subheader py-2 py-lg-4 subheader-solid"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
    style="background-color: none !important;"
    >
  
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!-- <i class="mdi mdi-arrow-left text-primary pt-1 pr-2" small></i>

        <h5 class="text-dark font-weight-bold my-2 mr-2" style="font-family:Poppins;  font-weight: 600 !important;">
          {{ title }}
        </h5> -->
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-1"
        >
          <!-- <li class="breadcrumb-item">
            <router-link :to="'#'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li> -->

          <template v-for="(breadcrumb, i) in breadcrumbs">

            <li class="breadcrumb-item" :key="`${i}-${breadcrumb.title}`">
              <router-link
                v-if="breadcrumb.route"
                :to="{ name: breadcrumb.route}"
                class="text-primary title font-poppins"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span class="text-primary title  font-poppins" v-else>
                {{ breadcrumb.title }}
              </span>


              <span
        v-if="i < breadcrumbs.length - 1"
        class="small-icon text-primary px-3 pb-1"
      >
        >
      </span>
            </li>
            <!-- <span class="small-icon text-primary px-3 pb-1">></span> -->

          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
        //  classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
.subheader{
  background-color: #F6FBFF !important;
}
.small-icon{
  font-size: 15px !important;
  font-weight: 900 !important;
}
.breadcrumb.breadcrumb-dot .breadcrumb-item:after{
  content: none !important

}
.title{
 font-weight: 600 !important;
 
}
</style>
