<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <div
          v-if="headerMenuEnabled"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
          <!-- example static menu here -->
          <KTMenu></KTMenu>
        </div>
        <h2  >
          {{ ($store.state.auth.normal) ? currentUserPersonalInfo.business_name : "Super Admin" }}
          <!-- {{ business_name }} -->
        </h2>
      </div>
      <!-- end:: Header Menu -->
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";
import { BUSINESS_DETAILS } from "@/core/services/store/config.module";

export default {
  name: "KTHeader",
  data() {
    return {
      business_name : "",
      store_name : '',
    };
  },
  components: {
    KTTopbar,
    KTMenu
  },
  mounted() {
    // let userAuth = sessionStorage.getItem('USER_STATE');
    // let authParsed = JSON.parse(userAuth);
    // if (authParsed.normal) {
    //   this.getBusinessName();
    // }else {
    //   this.business_name = 'Super Admin'
    //   this.store_name = 'Portal';
    // }

    // if (this.$store.state.auth.normal) {
    //   //get business name
    //   this.getBusinessName();
    // }else {
    //   this.business_name = 'Super Admin'
    //   this.store_name = 'Portal';
    // }

    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });

  },
  methods : {
    getBusinessName() {
      this.$store
        .dispatch(BUSINESS_DETAILS)
        .then((resp) => {
            this.business_name = resp.data.name;
            this.store_name = resp.data.store_name;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "currentUserPersonalInfo"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },


    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
<style scoped>

h2{
 font-family:'Poppins',sans-serif  !important;
 color:#262561 !important;
 font-weight: bold !important;
 text-transform:uppercase;
 font-size: 30px !important;
}
.header {
   /* background-color: red !important; */
}
.header-fixed.subheader-fixed .subheader {
  background-color: black !important;
}
</style>