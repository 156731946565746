<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo">
    <img
      alt="Logo"
      :src="logo"
      width="100"
      v-if="logo != '' && $store.state.auth.normal"
    />
    <img
      alt="Logo"
      :src="siteLogo()"
      width="100"
      v-if="!$store.state.auth.normal"
    />
    <div class="spinner" v-bind:class="spinnerClass || 'spinner-primary'"></div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "Loader",
  props: {
    logo: String,
    spinnerClass: String,
  },
  methods: {
    siteLogo() {
      return process.env.VUE_APP_API_BASE_URL + "default-logo.png";
    },
  },
};
</script>
