<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
    <div class="">
      <router-link to="/">
        <!-- <h2 class="logo-text mt-3">GetMeFood</h2> -->
        <img src="@/assets/gmflogo.png" alt="Logo" class="mt-2" style="width: 8em" />
        <!-- Getmefood -->
      </router-link>
    </div>
    <div class="brand-tools" v-if="allowMinimize && false">
      <button
        class="brand-toggle btn btn-sm px-0"
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
      >
        <span class="svg-icon svg-icon svg-icon-xl">
          <inline-svg
            class="svg-icon"
            src="media/svg/icons/Navigation/Angle-double-left.svg"
          />
        </span>
      </button>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
.svg-logo{
  margin: 0 auto;
}

.svg-logo svg {
  width:100%;
  height: 40px;
}
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";

export default {
  name: "KTBrand",
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    siteLogo() {
      console.log(process.env.VUE_APP_API_BASE_URL + "gmf-logo.png");
      if (!this.$store.state.auth.normal) {
        return process.env.VUE_APP_API_BASE_URL + "gmf-logo.png";
      }
      // return (
      //   process.env.VUE_APP_API_BASE_URL +
      //   this.$store.state.profile.user_personal_info.logo
      // );
    },
    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    },
  },
};

</script>
<style scoped>
.logo-text{
  font-family:Poppins,sans-serif  !important;
  color:#F6FBFF !important;
  font-weight: 900;

}
</style>
