<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          {{ year }} &nbsp;&copy;&nbsp;
        </span>
        <a
          href="#"
          target="_blank"
          class="text-dark-75 text-hover-primary"
        >
          getmefood.co.uk
        </a>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  data() {
    return {
      year: "",
    };
  },
  mounted(){
    var d = new Date();
    this.year = d.getFullYear();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
<style scoped>
.footer{
  position: fixed;
    bottom: 0px;
    /* left: 0; */
    width: 100%;
}
</style>
