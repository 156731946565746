import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDMiurrH6_se2d9aIVXt-_y9uE2sfCR3is",
    authDomain: "getmefood-2ce1b.firebaseapp.com",
    projectId: "getmefood-2ce1b",
    storageBucket: "getmefood-2ce1b.firebasestorage.app",
    messagingSenderId: "658732659168",
    appId: "1:658732659168:web:39a182a7310c8b0de13b85",
    measurementId: "G-Y50ZLH36VL"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Messaging
const messaging = getMessaging(app);

export { messaging, onMessage, getToken };
