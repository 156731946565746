<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto text-primary btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
    <i class="mdi mdi-account menu-icon text-danger" style="color: gray !important;"></i>

Hi 
    <span class="text-primary " style="text-transform:lowercase;">
              &nbsp;{{ currentUserPersonalInfo.name }}

    </span>
  
    <!-- <v-avatar
        class="bg-indigo"
        color="success"
        size="40"
      >
        <img
          src="https://cdn.vuetifyjs.com/images/john.jpg"
          alt="John"
        >
      </v-avatar> -->
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5" v-if="false">
            <img
              class="symbol-label"
              :src="currentUserPersonalInfo.photo"
              alt=""
            />
            <i class="symbol-badge bg-primary"></i>
          </div>
          <div class="d-flex flex-column">
            <router-link
              to="/custom-pages/profile"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ currentUserPersonalInfo.name }}
            </router-link>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUserPersonalInfo.email }}
                  </span>
                </span>
              </a>
            </div>
            <!-- Show sign out button only if not impersonating -->
         
            <!-- Show switch back button if impersonating -->
            <button v-if="isImpersonating " class="btn btn-light-danger btn-bold" @click="endImpersonation">
              Switch Back to Admin 
            </button>
            <button v-else class="btn bg-primary text-light btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { LOGOUT, END_IMPERSONATION } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  computed: {
    ...mapGetters([
      "currentUserPersonalInfo",
      "isImpersonating" // Map the Vuex getter to a computed property
    ]),
    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    }
  },
  isImpersonating(newVal) {
    console.log("isImpersonating changed:", newVal);
    // Optionally perform additional logic or force update
  },
  methods: {
  
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    endImpersonation() {
      this.$store
        .dispatch(END_IMPERSONATION)
        .then(() => {
          this.$store.state.isImpersonating = false;
          this.$forceUpdate()
         
          this.$router.push({ name: "businesses" });
          const closeButton = document.getElementById('kt_quick_user_close');
          closeButton.click();
    //        setTimeout(() => {
    //   window.location.reload()
    // }, 500);
          // Optionally update Vuex state here if needed
        });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  }
};
</script>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>
