<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--
    <div
      @click="takeMeToWeb"
      class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
    >
    <i class="bi bi-droplet-half fs-5x"></i>
      <span class="svg-icon svg-icon-xl svg-icon-primary">
        <inline-svg src="/media/svg/icons/Home/Earth.svg" />
      </span>
      <span class="pulse-ring"></span>
    </div>
  -->
    <!-- <label @click="takeMeToWeb" class="mr-3 mt-4">Go to Website</label> -->
    <i class="fa fa-globe mt-3 mr-5 text-primary"
    v-if="$store.state.auth.normal"
     @click="takeMeToWeb"
     title="View Website"
     aria-hidden="true"
     style="font-size:25px;cursor:pointer">
    </i>
    
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
//import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
//import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
//import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
//import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
//import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    //KTSearchDefault,
    KTDropdownNotification,
    //KTDropdownQuickAction,
    //KTDropdownMyCart,
    //KTDropdownLanguage,
    KTQuickUser,
    //KTQuickPanel
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    takeMeToWeb() {
      console.log(this.currentUserPersonalInfo.store_url);
      window.open(this.currentUserPersonalInfo.store_url, '_blank');
    }
  },
  computed: {
    ...mapGetters([
      "currentUserPersonalInfo",
    ]),
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>
